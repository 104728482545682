.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Little style */

.label-style-me{
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    box-shadow: none;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    display: block;
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    overflow-wrap: break-word;
    
}

.required-start::after{
  content: " *";
    color: rgb(164, 38, 44);
    padding-right: 12px;
}

.error-text{
    color: rgb(164, 38, 44);
    font-size: 10px;
    font-weight: 700;
}



.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid black;
}

.label-size-red-error{
  padding-top: 5px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
}

/* ANTD Modifications */

.ant-menu-horizontal{
  border-bottom: none;
  overflow-x: hidden;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #383838 !important;
}

.ant-radio-wrapper{
  color: black ;
}

.ant-select-selection-placeholder{
  opacity: 1 !important;
  color: black !important;
}

.ant-radio-inner{
  border-color: #383838 !important;
}


.ant-descriptions-title{
  margin-bottom: 0px;
}

.ant-descriptions-item-content{
  color: black !important;
}

.ant-select{
  color: black !important;
}

.ant-select-item-option-content{
  color: black !important;
}


.ant-card-body {
  padding: 14px;
}


.new-alert-text {
  animation: colorchange 0.5s infinite;
}

@keyframes colorchange {
  0% { color: #EBAE38; }
  50% { color: red; }
  100% { color: #EBAE38; }
}

h1 {
  font-family: Montserrat !important;
}

.ant-menu-item-selected{
  background-color: #992523;
  border-top: none !important;
  border-bottom: none !important;
  color: white !important;
}
.ant-menu-horizontal > .ant-menu-item{
  top: 0px !important;
  color: aliceblue;

  overflow-x: hidden;

}

.ant-menu-horizontal > .ant-menu-item:hover{
  color: white
  
}

.ant-back-top{
  bottom: 10px !important;
  right: 14px !important;
}

.my-table-own{
  width: 100%;
  border-collapse: collapse;
  overflow: none !important;
}

.my-table-own > thead > tr > th {
  border: 1px solid black;
  color: black;
  padding: 5px;
  overflow: none !important;
}

.my-table-own > tbody > tr > td {
  border: 1px solid black;
  color: black;
  padding: 5px;
  overflow: none !important;
}


.download-table-xls-button {
  background-color: blue;
  border: none;
  padding: 3px 8px 3px 8px;
  font-size: 12px;
  font-weight: 700;
  color: white;
  border-radius: 0;
}

.datepicker{z-index:9999 !important}

.site-page-header{
  background-color: white;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 25px;
  font-size: 11px;
}

/*changing padding left of menu*/
.ant-menu-submenu-title {
  padding-left: 10px !important;
}

/* changing submenu size*/
.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 25px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
  padding-left: 25px !important;
  margin-left: 0px !important;
  font-size: 10px !important;
}

/* changing icon size */
.ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 12px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}

.site-main{
  padding: 5px;
}

.events{
  list-style-type: none;
}

.sc-launcher {
  
  z-index: 999 !important;
}

.sc-chat-window {
  z-index: 999 !important;
}